import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { Image, Video, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3>{`Plan your route and export a .gpx file`}</h3>
    <br />
    <p>
  You want to connect a few trails and plan the whole tour? Know how far it is and how many 
  height meters you have to overcome? Export a .gpx file to navigate on your GPS device, or 
  upload it to Trailguide? Then a digital route planner is the perfect tool for that! 
  <br />
  <br />
      <h4>{`Free route planners`}</h4>
      <p>{`Several popular platforms provide route planners. But most of them require a subscription.
Nevertheless, there are free alternatives! Here we want to briefly introduce two of them:`}</p>
      <br />
      <Link href="https://web.locusmap.app" mdxType="Link">1) Locus Map</Link>
      <br />
      <Link href="https://bikerouter.de" mdxType="Link">2) Bikerouter</Link>
      <br />
      <br />
      <h4>{`Locus Map`}</h4>
  <Link href="https://web.locusmap.app" mdxType="Link">Locus Map</Link> is a native app that also has a 
  browser based web route planner. The work flow is:
  <br />
  - Select a routing profile: "MTB"<br />
  - Zoom the map to where you want to go<br />
  - Right click to set a start or end point<br />
  - Drag the route in between to the points you want to go to
  <br />
  <br />
  Positive: Locus Map provides elevation data to the route so you see a hight profile and 
  how much you have to climb. They have a really good base map.
    </p>
    <br />
    <Image src="/news/locusmap_routeplanner.jpg" mdxType="Image">
  Screenshot Locus Map route planner with height profile.
    </Image>
    <br />
    <br />
    <h4>{`Bikerouter`}</h4>
    <p>
  <Link href="https://bikerouter.de" mdxType="Link">Bikerouter</Link> is based on an open
  source project. The work flow is:
  <br />
  - Select a routing profile: "MTB standard"
  <br />
  - Zoom the map to where you want to go
  <br />
  - Just click on the map to add points to the route
  <br />
  - Drag the route in between to add further points
  <br />
  <br />
  Positive: Bikerouter is configurable, so you can edit the routing profiles, and
  add custom map layers or overlays. It offers many routing profiles by default,
  and the workflow is very smooth. On the downside: elevation data is only available
  up to 60° North (just south of Oslo).
    </p>
    <br />
    <Image src="/news/bikerouter_routeplanner.jpg" mdxType="Image">
  Screenshot Bikerouter route planner.
    </Image>
    <br />
    <p>
  With both route planners you can export .gpx files to be used on your GPS
  device for navigation or to be uploaded to Trailguide. See how to add a trail
  to Trailguide <Link href="/howto/add-trail" mdxType="Link">here</Link>.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      